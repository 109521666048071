import React, { useState, useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Typography } from '@mui/material';
import GoogleSheetProvider, { GoogleSheetContext } from './GoogleSheetProvider';
import Dashboard from './components/Dashboard';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import Login from './components/Login';
import "./Style.css";
import logo from './assets/logo.png';

const theme = createTheme();

const getStatusIcon = (status) => {
  switch (status) {
    case 'charging':
      return <BatteryChargingFullIcon style={{ color: 'green' }} />;
    case 'discharging':
      return <BatteryAlertIcon style={{ color: 'red' }} />;
    case 'no connected':
    default:
      return <BatteryUnknownIcon style={{ color: 'gray' }} />;
  }
};

const getStatusStyle = (status) => {
  switch (status) {
    case 'charging':
      return { color: 'green' };
    case 'discharging':
      return { color: 'red' };
    case 'no connected':
    default:
      return { color: 'gray' };
  }
};

const Header = () => {
  const data = useContext(GoogleSheetContext);

  if (!data || data.length === 0) return null;

  const status = data[0].Mode.toLowerCase();

  return (
    <Box textAlign="center" mb={4} className="header-container">
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={logo} alt="Logo" style={{ maxWidth: '80%', height: 'auto' }} />
        <Typography variant="h3" component="div" mt={2}>
          Monitoring Dashboard
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
        {getStatusIcon(status)}
        <Typography variant="h6" component="span" style={getStatusStyle(status)} ml={1}>
          {status}
        </Typography>
      </Box>
    </Box>
  );
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isAuthenticated ? (
        <Login onLogin={handleLogin} />
      ) : (
        <GoogleSheetProvider>
          <Container className="dashboard-container">
            <Header />
            <Dashboard className="dashboard-content" />
          </Container>
        </GoogleSheetProvider>
      )}
    </ThemeProvider>
  );
}

export default App;