import React from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';


const Login = ({ onLogin }) => {
  return (
    <Box className="login-container" textAlign="center">
      <Typography variant="h3" mb={3} color="Black">
        Login
      </Typography>
      <Box mb={2}>
        <TextField label="Username" variant="outlined" fullWidth margin="dense" />
      </Box>
      <Box mb={3}>
        <TextField label="Password" type="password" variant="outlined" fullWidth margin="dense" />
      </Box>
      <Button variant="contained" color="primary" onClick={onLogin}>
        Login
      </Button>
    </Box>
  );
};

export default Login;
